//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "IndexPage",
  data() {
    return {
      sliderOurStoresApps: [
        {
          title: "متجر راحتي",
          details:
            "من عملاء منصتنا المتميزين ومن أكبر المتاجر  المتخصصة بالأثاث والمفارش بالمملكة ، أكثر من 11 فرع منتشر حول المملكة.",
          imageUrl: "/assets/images/store-app/slider-our-stores-apps/01.png",
          iosUrl:
            "https://apps.apple.com/sa/app/%D9%85%D8%AA%D8%AC%D8%B1-%D8%B1%D8%A7%D8%AD%D8%AA%D9%8A/id1498315789",
          androidUrl:
            "https://play.google.com/store/apps/details?id=com.khtwah.rahaty",
        },
        {
          title: "ويفر ديزاين",
          details:
            "مصنف من أفضل 200 تطبيق للتسوق على مستوى المملكة على منصة أبل ، يقدم أحدث تصاميم ملابس فريدة ومبدعة للجنسين.",
          imageUrl: "/assets/images/store-app/slider-our-stores-apps/02.png",
          iosUrl:
            "https://apps.apple.com/us/app/weaver-design-%D9%88%D9%8A%DA%A4%D8%B1-%D8%AF%D9%8A%D8%B2%D8%A7%D9%8A%D9%86/id1513176594",
          androidUrl:
            "https://play.google.com/store/apps/details?id=com.khtwah.weaverdesign",
        },
        {
          title: "ليتل روزا",
          details:
            "متجر متخصص بأزياء الأطفال الأسبانية يقدم مجموعة كبيرة ومتنوعة من أزياء الأطفال أولاد وبنات وحديثي ولادة .",
          imageUrl: "/assets/images/store-app/slider-our-stores-apps/03.png",
          iosUrl:
            "https://apps.apple.com/us/app/%D9%84%D9%8A%D8%AA%D9%84-%D8%B1%D9%88%D8%B2%D8%A7-little-rosa/id1604854424",
          androidUrl:
            "https://play.google.com/store/apps/details?id=com.khtwah.littlerosa",
        },
        {
          title: "أمل تليكوم",
          details:
            "متجر متخصص لبيع الهواتف الذكية واكسسوارات الموبايل ويقدم كذلك خدمات الصيانة المميزة.",
          imageUrl: "/assets/images/store-app/slider-our-stores-apps/04.png",
          iosUrl:
            "https://apps.apple.com/sa/app/%D8%A3%D9%85%D9%84-%D8%AA%D9%8A%D9%84%D9%8A%D9%83%D9%88%D9%85-amal-telecom/id1621098928",
          androidUrl:
            "https://play.google.com/store/apps/details?id=com.khtwah.amaltelecom",
        },
        {
          title: "ماي نيش",
          details:
            "يقدم مجموعة مختلفة من عطور النيش والعطور العالمية مع ضمان الجودة و خدمة التوصيل خلال 24 ساعة.",
          imageUrl: "/assets/images/store-app/slider-our-stores-apps/05.png",
          iosUrl:
            "https://apps.apple.com/sa/app/%D9%85%D8%A7%D9%8A-%D9%86%D9%8A%D8%B4-myniche/id1560842089?l=ar",
          androidUrl:
            "https://play.google.com/store/apps/details?id=com.khtwah.myniche",
        },
        {
          title: "لارينا بتيت",
          details:
            "من التطبيقات الرائدة في تقديم ملابس الأطفال أكثر من 20 الف مستخدم للتطبيق على منصتي جوجل وأبل.",
          imageUrl: "/assets/images/store-app/slider-our-stores-apps/06.png",
          iosUrl:
            "https://apps.apple.com/sa/app/%D9%84%D8%A7%D8%B1%D9%8A%D9%86%D8%A7-%D8%A8%D8%AA%D9%8A%D8%AA-la-rina-petite/id1500181309?l=id",
          androidUrl:
            "https://play.google.com/store/apps/details?id=com.khtwah.larinapetite",
        },
        {
          title: "عبايات اللمسة الجميلة",
          details:
            "هذا ثاني متجر وتطبيق سويناه معهم الأول بالسعودية والثاني بالامارات الحمدلله ينافس المواقع المشهورة من حيث التصميم وسرعة التصفح.",
          imageUrl: "/assets/images/store-app/slider-our-stores-apps/07.png",
          iosUrl:
            "https://apps.apple.com/sa/app/%D8%B9%D8%A8%D8%A7%D9%8A%D8%A7%D8%AA-%D8%A7%D9%84%D9%84%D9%85%D8%B3%D8%A9-%D8%A7%D9%84%D8%AC%D9%85%D9%8A%D9%84%D8%A9/id1516823736",
          androidUrl:
            "https://play.google.com/store/apps/details?id=com.khtwah.abayatallmsa",
        },
      ],
      sliderMobileApplication: [
        {
          title: "وسع تجارتك عن طريق تطبيق جوال احترافي بهوية متجرك",
          imageUrl: "/assets/images/home/slider-mobile-application/01.png",
        },
        {
          title: "عزز علامتك التجارية واكسب ثقة عملائك",
          imageUrl: "/assets/images/home/slider-mobile-application/02.png",
        },
        {
          title: "4x زيادة في معدلات الشراء من خلال تطبيقات التسوق",
          imageUrl: "/assets/images/home/slider-mobile-application/03.png",
        },
        {
          title: "سوق لمتجرك وتواصل بشكل فعال من خلال نظام الاشعارات",
          imageUrl: "/assets/images/home/slider-mobile-application/04.png",
        },
        {
          title: "لوحة تحكّم واحدة للمتجر والتطبيق",
          imageUrl: "/assets/images/home/slider-mobile-application/05.png",
        },
      ],
      sliderElements: [
        {
          title: "القصص التفاعلية",
          imageUrl: "/assets/images/home/slider-elements/01.png",
        },
        {
          title: "صفحات مخصصة",
          imageUrl: "/assets/images/home/slider-elements/02.png",
        },
        {
          title: "عرض أفقي ورأسي للمنتجات",
          imageUrl: "/assets/images/home/slider-elements/03.png",
        },
        {
          title: "النوافذ المنبثقة",
          imageUrl: "/assets/images/home/slider-elements/04.png",
        },
        {
          title: "شريط الأقسام المتحرك",
          imageUrl: "/assets/images/home/slider-elements/05.png",
        },
        {
          title: "العداد التنازلي للعروض",
          imageUrl: "/assets/images/home/slider-elements/06.png",
        },

      ],
      title: "تجدد - امتلك تطبيق لمتجرك الالكتروني في ٢٤ ساعة",
      description: "منصة تجدد ، أنشئ  تطبيق جوال احترافي لمتجرك الالكتروني في ٢٤ ساعة فقط بنفسك و بدون أي خبرة تقنية ",
      reviews: [
        {
          masses:
            "هذا ثاني متجر وتطبيق سويناه معهم والحمدلله ينافس المواقع المشهورة من حيث التصميم وسرعة التصفح ونطمح للافضل وشكرا لتعاونكم الدائم معنا ودمتم بخير.",
          fullName: "عبدالله محمد الوصابي",
          info: "عبايات اللمسة المميزة",
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses:
            "فريق الدعم الفني أولًا، فريق رائع بشتى النواحي .. خدمة راقيه و مميزة.",
          fullName: "احمد علي",
          info: "متجر دار حروف",
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses:
            "نشكر منصة متجرة على الدعم مستمر بالمتجر والتجاوب مع اي استفسار ، رد ودعم سريع ومنصة ممتازة جدا وسهولة للتواصل مع خدمة العملاء كلمة شكر لا تكفي متجرة.",
          fullName: "محمد خليل",
          info: "متجر بيت الأطفال",
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses:
            "من غير مجامله وشهادة حق وبكل أمانة فريق متجرة متعاونين جداً ومتجاوبين وكنتم أحد اعمدة النجاح بالنسبة لنا ،",
          fullName: "عبدالله محمد الوصابي",
          info: "عبايات اللمسة الجميلة",
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses:
            "منصة جميلة و سهلة وواجهات بسيطة . و ما شاء الله عليكم اي مشكلة تنحل باسرع وقت . فيكم الخير و البركة٫",
          fullName: "تركي الزهراني",
          info: "متجر أسماك الزينة",
          pic: "/assets/images/flags/Flags-2.png",
        },
      ],
    };
  },
  head() {
    return {
      title: this.title,
      description: this.description,
      keywords: 'انشاء متجر الكتروني, تصميم متجر الكتروني, تجهيز متجر الكتروني',
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.description,
        },
        {
          hid: "og:locale", // open graph type
          property: "og:locale",
          content: "ar_AR",
        },
        {
          hid: "og:type", // open graph type
          property: "og:type",
          content: "article",
        },
        {
          hid: "og:title", // open graph title
          property: "og:title",
          content: this.title,
        },
        {
          hid: "og:description", // open graph description
          property: "og:description",
          content: this.description,
        },
        {
          hid: "og:url", // open graph url
          property: "og:url",
          content: "https://tajadad.app",
        },
        {
          hid: "og:site_name", // open graph site_name
          property: "og:site_name",
          content: "متجرة",
        },
        {
          hid: "og:updated_time", // open graph updated_time
          property: "og:updated_time",
          content: "2022-12-10T00:08:47+03:00",
        },
        {
          hid: "og:image", // open graph image
          property: "og:image",
          content:
            "https://tajadad.app/tajadad.jpg",
        },
        {
          hid: "apple-mobile-web-app-title", // open graph mobile web app title
          name: "apple-mobile-web-app-title",
          content: this.title,
        },

        // open graph twitter
        { name: "twitter:site", content: "@Tajadad" },
        { name: "twitter:card", content: "summary_large_image" },
        {
          hid: "twitter:url",
          name: "twitter:url",
          content: "https://tajadad.app",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: this.title,
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content: this.description,
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content:
            "https://tajadad.app/tajadad.jpg",
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: "https://tajadad.app/",
        },
        {
          rel: "next",
          href: "https://tajadad.app/prices",
        },
      ],
    };
  },
  jsonld() {
    const items = this.reviews.map((item) => ({
      "@type": "Review",
      "author": { "@type": "Person", "name": item.fullName },
      "datePublished": "2022-01-01",
      "name": item.fullName,
      "reviewBody": item.masses,
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5"
      },
    }));
    return {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebSite",
          "@id": "https://tajadad.app#website",
          "author": { '@id': '#identity' },
          "copyrightHolder": { '@id': '#identity' },
          "copyrightYear": '2022-10-14T10:10:00-07:00',
          "creator": { '@id': '#creator' },
          "dateModified": '2022-10-14T10:10:00-07:00',
          "datePublished": '2022-10-14T10:10:00-07:00',
          "description":
            "انشاء متجر الكتروني احترافي اصبح سهل مع منصة متجرة التي تساعدك في الحصول على متجر إلكتروني فعال في 3 خطوات حتى تبيع منتجاتك بنجاح في متجر على الإنترنت دون الحاجة لتعلم البرمجة  أو كتابة الأكواد",
          "headline": "منصة متجرة",
          "image": {
            '@type': 'ImageObject',
            "url": "https://tajadad.app/tajadad.jpg",
          },
          "inLanguage": 'ar_AR',
          "mainEntityOfPage": "https://tajadad.app/",
          "name": "منصة متجرة",
          "publisher": { '@id': '#creator' },
          "url": "https://tajadad.app/",
        },
        {
          "@type": "Organization",
          "@id": "https://tajadad.app/#organization",
          "url": "https://tajadad.app/",
          "name": "منصة متجرة",
          "alternateName": "منصة متجرة",
          "description":
            "انشاء متجر الكتروني احترافي اصبح سهل مع منصة متجرة التي تساعدك في الحصول على متجر إلكتروني فعال في 3 خطوات حتى تبيع منتجاتك بنجاح في متجر على الإنترنت دون الحاجة لتعلم البرمجة  أو كتابة الأكواد",
          "logo": {
            "@type": "ImageObject",
            "url": "https://tajadad.app/tajadad.jpg",
          },
          "image": {
            '@type': 'ImageObject',
            "url": "https://tajadad.app/tajadad.jpg",
          },
          "contactPoint": [
            // {
            //   "@type": "ContactPoint",
            //   "telephone": "920033179",
            //   "contactType": "customer service"
            // }
          ]
        },
        {
          "@type": "WebPage",
          "mainEntity": {
            "@type": "Book",
            "author": "/",
            "bookFormat": "https://schema.org/Paperback",
            "datePublished": "2022-10-01",
            "image": "https://tajadad.app/tajadad.jpg",
            "inLanguage": "Arabic",
            "name": "reviews",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": items.length
            },
            "review": items
          }
        }
      ]
    }
  }
};
